import { useCallback, useEffect,  useRef } from "react";
import { useUserInfo } from "./useAuth";
import {
    ADMIN,
    GROUP_ADMIN,
    MEMBERS,
    NODE_ADMIN,
    READ_ONLY,
    TENANT_ADMIN,
    USER
} from "../constants/layout";


export const getRoleValue = (role) => {
    if ([TENANT_ADMIN, ADMIN].includes(role)) {
        return 4;
    } else if ([NODE_ADMIN, GROUP_ADMIN].includes(role)) {
        return 3;
    } else if ([USER, MEMBERS].includes(role)) {
        return 2;
    } else if (role === READ_ONLY) {
        return 1;
    } else {
        return 0;
    }
};

export const useGetGlobalPermission = (reqRole) => {
    const { userdata: { role } } = useUserInfo();
    return (getRoleValue(reqRole) <= getRoleValue(role));

    // OLD - not working as is
    // const { userdata: { role } } = useUserInfo();
    // const hasPermissionRef = useRef(false);
  
    // const getPermision = useCallback(() => {
    //     hasPermissionRef.current = (getRoleValue(reqRole) <= getRoleValue(role));
    // }, [role, reqRole]);

    // useEffect(() => {
    //     getPermision();
    // }, [getPermision]);

    // return hasPermissionRef.current

}