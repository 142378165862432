import { Fragment } from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { useUserInfo } from "../hooks/useAuth";
import { ADMIN, READ_ONLY } from "../constants/layout";

export const ProtectedLicenseLayout = () => {
    const outlet = useOutlet();
    const { userdata, multitenant } = useUserInfo();

    if (
        multitenant &&
        userdata?.tenantid &&
        (
            userdata?.role === READ_ONLY ||
            (userdata?.role === ADMIN && !userdata?.probeadd))
    ) {
        return <Navigate to="/" />;
    }

    return <Fragment>{outlet}</Fragment>;
};
