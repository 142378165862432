import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Icon } from 'semantic-ui-react';
import { useFormFields } from '../../hooks/useFormFields';


const LocationParametersForm = (props) => {
    const id = useMemo(() => props.id,[props.id])
    const parametersList = useMemo(() => props.parameters,[props.parameters])
    const data = useMemo(() => JSON.parse(props.data || '{}'),[props.data])
    const selectedParams = useMemo(() => Object.keys(JSON.parse(props.data || '{}')),[props.data])

    const {
        handleSubmit,
        reset,
        register,
        setValue,
        getValues,
        watch,
        formState: { errors },
    } = useForm();

    const { renderInput, renderDropdown } = useFormFields({ register, errors, setValue, watch });

    const onSubmit = (payload) => {
        for (let key of Object.keys(payload)) {
            if (!payload.loc_params_choose.includes(key) && key !== "loc_params_choose") {
                delete payload[key]
            }
        }
        delete payload.loc_params_choose
        payload = JSON.stringify(payload)
        props.post(payload)
    };

    useEffect(() => {
        reset({loc_params_choose: selectedParams, ...data})
    }, [reset, id, selectedParams, data])

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group widths={2}>
                {id && renderDropdown("Location parameters", "loc_params_choose", parametersList, { notRequired: true, multiple: true })}
            </Form.Group>
            {(getValues("loc_params_choose") || []).map(e =>
                    <Form.Group widths={4} key={e} style={{ marginLeft: "3rem" }}>
                        <Icon
                            link
                            name="trash alternate"
                            color="red"
                            style={{marginTop: "2rem", marginRight: "1rem"}}
                            onClick={() => setValue("loc_params_choose", getValues("loc_params_choose").filter(k => k !== e))}
                        />
                        {renderInput(e, e, { validate: v => v !== "" })}
                    </Form.Group>
                )}
            <Form.Group>
                <Form.Button
                    size="small"
                    type='submit'
                    primary
                    content="Update"
                />
                <Form.Button
                    size="small"
                    type="button"
                    content="Cancel"
                    onClick={props.close}
                />
            </Form.Group>
        </Form>
    );
}

export default LocationParametersForm;