import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Grid, Form, Button } from "semantic-ui-react";
import _ from 'lodash';
import RenderObject from "../../../components/renderObject/RenderObject";
import { adjustConfig } from "../../../utils/configurator/adjustConfig";
import { adjustModel } from "../../../utils/configurator/adjustModel";
import { prepareConfig } from "../../../utils/configurator/prepareConfig";
import { PermissionsGateV } from "../../../layouts/PermissionGate/PermissionGateV";
import { useFetchSwModuleDetails } from "../../../hooks/useFetchSwModuleDetails";
import { useFetchProbeModule } from "../../../hooks/useFetchProbeModule";
import { useFetchResponders } from "../../../hooks/useFetchResponders";

const Configurator = (props) => {
    const probeid = props.probeid;
    const moduleid = props.moduleid;
    const instanceid = props.instanceid;
    const version = props.version;
    const hardlinked = props.hardlinked;
    let dynamics = {}
    const methods = useForm({ shouldUnregister: true });

    const { data: swModuleModel, isLoading: swModuleModelIsLoading } = useFetchSwModuleDetails({
        moduleid,
        version,
        select: (data) => {
            let model = adjustModel(JSON.parse(data.configmodel))
            // for (const name in model) {
            //     if (model[name]?.values === 'dynamic') {
            //         let responderSchema = {}

            //         // TODO: add this to config models
            //         if (moduleid === "twamp" || moduleid === "twampmulti") {
            //             responderSchema.value = {'host': 'destination', 'port': 'port'}
            //             responderSchema.original = 'hosts'
            //             responderSchema.type = 'object'
            //         } else if (moduleid === "networkbwclient") {
            //             responderSchema.value = {}
            //             responderSchema.original = 'host'
            //             responderSchema.type = 'string'
            //         } else if (moduleid === "csvoice") {
            //             responderSchema.value = {'ResponderNumber':'destination', 'ResponderTag':''}
            //             responderSchema.original = 'ResponderNumbers'
            //             responderSchema.type = 'object'
            //         } else if (moduleid === "smsprobe") {
            //             responderSchema.value = {'Number':'destination', 'Tag':''}
            //             responderSchema.original = 'BInfo'
            //             responderSchema.type = 'object'
            //         }

            //         model[name].responderSchema = responderSchema
            //         dynamics = {...dynamics, [name]: responderSchema}
            //     }
            // }
            return model;
        },
    });

    // const { data: responderList = [], isFetching: responderListFetching } = useFetchResponders({
    //     probeid,
    //     moduleid,
    //     select: (e) =>
    //         e.map((i) => {
    //             if (moduleid === "twamp" || moduleid === "twampmulti") {
    //                 return JSON.stringify({ host: i.destination, port: i.port });
    //             } else {
    //                 return i.destination;
    //             }
    //         }).filter(e => e !== ""),
    //     options: {
    //         refetchOnMount: "always",
    //     },
    // });

    const { data: nodeModuleData, isFetching: nodeModuleIsFetching, refetch } = useFetchProbeModule({
        probeid,
        moduleid,
        enabled: !swModuleModelIsLoading,
        instanceid,
        select: (data) => {
            if (data && data.hasOwnProperty("config")) {
                let config = JSON.parse(data.config);
                config = adjustConfig(config);
                // if (Object.keys(dynamics).length > 0) {
                //     for (const [name, schema] of Object.entries(dynamics)) {
                //         config[name] = [];
                //         let hosts = config[schema.original].map((e) => schema.type === 'string' ? e.var : JSON.stringify(e));
                //         for (const responder of responderList) {
                //             if (hosts.includes(responder)) {
                //                 config[name].push(responder);
                //                 let idx = hosts.indexOf(responder);
                //                 config[schema.original].splice(idx, 1);
                //                 hosts.splice(idx, 1);
                //             }
                //         }
                //     }
                // }
                return config;
            }
            return {};
        },
        options: {
            refetchOnMount: "always",
        },
    });

    const onSubmit = (values) => {
        const config = prepareConfig(values);

        if (_.isEqual(config, prepareConfig(nodeModuleData))) {
            props.noConfigChangeMessage()
            return
        }

        Object.entries(dynamics).forEach(([name, schema]) => {
            if (config.hasOwnProperty(name)) {
                config[schema.original] = Array.from(new Set([...(config[schema.original] || []), ...(schema.type === 'string' ? config[name] : config[name].map((e) => JSON.parse(e)))]))
                delete config[name]
            }
        })

        let data = {
            nodeid: props.probeid,
            moduleid: props.moduleid,
            instanceid: props.instanceid,
            config: JSON.stringify(config),
        };
        props.onFormSubmit(data).then(() => refetch())
    };

    useEffect(() => {
        if (!nodeModuleIsFetching) {
            methods.reset(nodeModuleData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodeModuleIsFetching]);

    return (
        <React.Fragment>
            {!swModuleModelIsLoading && !nodeModuleIsFetching && (
                <FormProvider {...methods}>
                    <Form style={{ padding: "0" }} onSubmit={methods.handleSubmit(onSubmit)}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <RenderObject
                                        {...{
                                            nodeid: probeid,
                                            moduleid: moduleid,
                                            inline: false,
                                            parentName: undefined,
                                            model: swModuleModel,
                                            hasPermision: props.hasPermission,
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row style={{ padding: "0.5rem 1rem" }} verticalAlign="middle">
                                {!hardlinked && (
                                    <Grid.Column width={6}>
                                        <Form.Field align="left">
                                            <PermissionsGateV hasPermission={props.hasPermission}>
                                                <Button
                                                    type="submit"
                                                    size="small"
                                                    primary
                                                    content='Update'
                                                    // disabled={_.isEqual(prepareConfig(methods.watch()), prepareConfig(nodeModuleData))}
                                                />
                                            </PermissionsGateV>
                                            <PermissionsGateV hasPermission={props.hasPermission}>
                                                <Button
                                                    type="button"
                                                    size="small"
                                                    onClick={() => methods.reset(nodeModuleData)}
                                                    content='Reset'
                                                />
                                            </PermissionsGateV>
                                            <PermissionsGateV hasPermission={props.hasPermission}>
                                                <Button
                                                    size="small"
                                                    type="button"
                                                    onClick={props.switchView}
                                                    content='Switch view'
                                                />
                                            </PermissionsGateV>
                                        </Form.Field>
                                    </Grid.Column>
                                )}
                            </Grid.Row>
                        </Grid>
                    </Form>
                </FormProvider>
            )}
        </React.Fragment>
    );
};

export default Configurator;
