import React, { useEffect, useRef, useState } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router';
import { Divider, Breadcrumb, Grid, Icon, Select, Loader, Card, Container, Label } from 'semantic-ui-react';
import NMService from '../../services/nm.service';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import ProbeDetails from './ProbeDetails';
import ProbeData from './ProbeData';
import ProbePermissions from './ProbePermissions';
import NetworkManagment from './NetworkManagment/NetworkManagment';
import AssignedModules from './AssignedModules/AssignedModules';
import InstalledModules from './InstalledModules/InstalledModules';
import UnassignedModules from './UnassignedModules/UnassignedModules';
import { getProbePermission } from '../../helpers/userPermission';
import { useUserInfo } from '../../hooks/useAuth';
import { PermissionsGateV } from '../../layouts/PermissionGate/PermissionGateV';
import AnchorStyleButton from '../../layouts/AnchorStyleButton/AnchorStyleButton';
import { addGlobalMessageAtom } from '../../store/globalMessage';
import { useFetchProbeDetails } from '../../hooks/useFetchProbeDetails';
import { useFetchProbeModuleBundle } from '../../hooks/useFetchProbeModuleBundle';
import { useFetchSwModules } from '../../hooks/useFetchSwModules';
import { useFetchAvailableUpgradeLicenses } from '../../hooks/useFetchAvailableUpgradeLicenses';
import { useFetchAvailableLicenses } from '../../hooks/useFetchAvailableLicenses';
import { useFetchProbeLicense } from '../../hooks/useFetchProbeLicense';
import { editProbeAtom } from '../../store/editProbe';
import VPN from './VPN/VPN';
import ProbeLicense from './ProbeLicense';

const ProbeEdit = () => {
    const { probeid } = useParams();
    const navigate = useNavigate();
    const { userdata: { role: userRole, groups: userGroups } } = useUserInfo();
    const selectedLicenseRef = useRef();

    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [probeState, setProbeState] = useAtom(editProbeAtom);
    const [showModal, setShowModal] = useState(undefined)

    const {
        data: availableLicenses = []
    } = useFetchAvailableLicenses({
        options: {
            enabled: showModal === 'assignLicense',
            refetchOnMount: 'always',
        },
        select: e => {
                    let options = [];
                    e["v1lpmn"] && options.push({
                        key: "v1lpmn",
                        value: "v1lpmn",
                        text: "5x9 Lightweight Node - Micro (" + e["v1lpmn"] + ")",
                        title: "v1lpmn",
                    });
                    e["v1lps"] && options.push({
                        key: "v1lps",
                        value: "v1lps",
                        text: "5x9 Lightweight Node - Small (" + e["v1lps"] + ")",
                        title: "v1lps",
                    });
                    e["v1lpm"] && options.push({
                        key: "v1lpm",
                        value: "v1lpm",
                        text: "5x9 Lightweight Node - Medium (" + e["v1lpm"] + ")",
                        title: "v1lpm",
                    });
                    e["v1lpl"] && options.push({
                        key: "v1lpl",
                        value: "v1lpl",
                        text: "5x9 Lightweight Node - Large (" + e["v1lpl"] + ")",
                        title: "v1lpl",
                    });
                    return options
        }
    })

    const {
        data: probeDetails,
        isError: isDetailsError
    } = useFetchProbeDetails({
        probeid,
        options: {
            refetchOnMount: 'always',
        }
    });

    const {
        data: probeLicense,
        refetch: refetchProbeLicense,
        isLoading: probeLicenseIsLoading
    } = useFetchProbeLicense({
        probeid,
        options: {
            refetchOnMount: 'always',
        }
    })

    const {
        refetch: refetchModuleBundle,
        isLoading: probeModuleBundleIsLoading,
        isError: isModuleBundleError
    } = useFetchProbeModuleBundle({
        probeid,
        options: {
            refetchOnMount: 'always',
        }
    });

    const {
        isLoading: moduleListIsLoading
    } = useFetchSwModules({
        options: {
            refetchOnMount: 'always',
        }
    });

    const {
        data: availableUpgradeLicense
    } = useFetchAvailableUpgradeLicenses({
        licenseCode: probeLicense.licenseCode,
        options: {
            refetchOnMount: 'always',
            enabled: !!probeLicense.licenseCode && showModal === 'upgradeLicense'
        }
    })

    const reboot = () => NMService.executeOnDemand({ nodeid: probeid, command: 'reboot' }).then(() =>
                                addGlobalMessage({
                                    header: 'Node scheduled for reboot!',
                                    content: `Node with ID "${probeid}" scheduled for reboot.`,
                                    type: 'positive',
                                })).catch(e => null)
                                .finally(() => setShowModal(undefined))

    const updateNode = () => NMService.executeOnDemand({ nodeid: probeid, command: 'updateNode' }).then(() =>
                                addGlobalMessage({
                                    header: 'Node scheduled for upgrade',
                                    content: `Node with ID "${probeid}" scheduled for upgrade.`,
                                    type: 'positive',
                                })).catch(e => null)
                                .finally(() => setShowModal(undefined))

    const shutdown = () => NMService.executeOnDemand({ nodeid: probeid, command: 'shutdown' }).then(() =>
                                addGlobalMessage({
                                    header: 'Node scheduled for shutdown!',
                                    content: `Node with ID "${probeid}" scheduled for shutdown.`,
                                    type: 'positive',
                                })).catch(e => null)
                                .finally(() => setShowModal(undefined))

    const startBlinking = () => NMService.executeOnDemand({ nodeid: probeid, command: 'identify' }).then(() =>
                                addGlobalMessage({
                                    header: 'Node identification was started',
                                    content: `Identification for node with ID "${probeid}" started.`,
                                    type: 'positive',
                                })).catch(e => null)
                                .finally(() => setShowModal(undefined))
    

    const deleteNode = () => NMService.deleteNode(probeid).then(() => {
                                    navigate('/nodes');
                                    addGlobalMessage({
                                        header: 'Node was deleted successfully!',
                                        content: `Node with HW ID "${probeDetails.hwid}" is unmanaged now.`,
                                        type: 'positive',
                                    })
                                }).catch(e => null)
                                .finally(() => setShowModal(undefined))

    const assignLicenseToProbe = () => NMService.assignLicenseToNode(selectedLicenseRef.current, probeid).then(() =>
                                            addGlobalMessage({
                                                header: "License successfully applied",
                                                content: "License successfully applied",
                                                type: "positive",
                                            })
                                        ).catch(e => null)
                                        .finally(() => {
                                            refetchProbeLicense()
                                            setShowModal(undefined)
                                        })

    const upgradeLicense = () => NMService.upgradeLicense(selectedLicenseRef.current, probeid).then(() =>
                                            addGlobalMessage({
                                                header: "License successfully upgraded",
                                                content: "License successfully upgraded",
                                                type: "positive",
                                            })
                                        ).catch(e => null)
                                        .finally(() => refetchProbeLicense())

    const createGraphs = () => NMService.createDefaultGraphsForNode(probeid).then((r) =>
                                            addGlobalMessage({
                                                header: r.data?.message ? r.data.message : 'Created default graphs!',
                                                content: r.data?.message ? r.data.message : `Successfully created default graphs for node ID "${probeid}".`,
                                                type: r.data?.message ? 'warning' : 'positive',
                                            })
                                        ).catch(e => null)
                                        .finally(() => setShowModal(undefined))

    const removeLicenseFromProbe = () =>
        NMService.removeLicenseFromNode(probeid).then(() =>
            addGlobalMessage({
                header: "License successfully removed",
                content: "License successfully removed",
                type: "positive",
            })
        ).then(() => refetchModuleBundle())
        .catch(e => null)
        .finally(() => {
            refetchProbeLicense()
            setShowModal(undefined)
        })

    useEffect(() => {
        if (probeDetails.groups && probeDetails.permissions) {
            const probePermission = getProbePermission(userRole, userGroups, probeDetails.groups, probeDetails.permissions);
            setProbeState({
                type: 'set-permissions',
                value: {
                    id: probeid,
                    permissions: probePermission
                }
            })
        }
        return () => setProbeState({ type: '' });
    }, [probeid, probeDetails?.groups, probeDetails?.permissions, userRole, userGroups]); //eslint-disable-line

    useEffect(() => {
        setProbeState({
            type: 'set-license',
            value: {
                uuid: probeLicense?.uuid ? probeLicense?.uuid : '',
                name: probeLicense?.uuid ? probeLicense?.name : '',
                quantity: probeLicense?.uuid ? probeLicense?.quantity : '',
            }
        })
    }, [probeLicenseIsLoading]) //eslint-disable-line

    const card = (name, content) =>
        <Card fluid style={{ height: '7rem', backgroundColor: 'rgba(65, 131, 196, 0.05)' }}>
            <Card.Content>
                <Card.Header content={name} />
                <Card.Description content={content} textAlign="center" />
            </Card.Content>
        </Card>

    if (!probeState.id || !probeState.permissions || moduleListIsLoading || probeLicenseIsLoading || probeModuleBundleIsLoading) {
        if (isDetailsError || isModuleBundleError) {
            return (
                <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Icon name='warning sign' size='massive' color='red' style={{ position: 'absolute', marginTop: '15rem' }}/>
                    <Label style={{ position: 'absolute', marginTop: '30rem' }}>Error while fetching data, please refresh page</Label>
                </Container>) 
        }
        return <Loader size='big' active inline='centered' style={{ marginTop: '10rem' }}/>
    }

    return (
        <>
            <Breadcrumb style={{ marginTop: '1rem' }}>
                <Breadcrumb.Section>Nodes</Breadcrumb.Section>
                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                <Breadcrumb.Section active>{probeDetails.name ? "Managed node" : "Newly managed node"}</Breadcrumb.Section>
            </Breadcrumb>
            <ProbeLicense
                probeLicense={probeLicense}
                availableUpgradeLicense={availableUpgradeLicense}
                hasPermission={probeState?.permissions?.editbasicdata}
                setShowModal={setShowModal}
            />
            <Divider hidden style={{ margin: '0 0 3rem 0' }}/>

            <Grid>
                <Grid.Row columns={4}>
                    <Grid.Column>{card('ID', probeDetails.id)}</Grid.Column>
                    <Grid.Column>{card('HWID', probeDetails.hwid)}</Grid.Column>
                    <Grid.Column>{card('Name', probeDetails.name)}</Grid.Column>
                    <Grid.Column>
                        <Card fluid style={{ backgroundColor: 'rgba(65, 131, 196, 0.05)', height: '7rem', display: 'table-cell', verticalAlign: 'middle' }}>
                            <Grid style={{ padding: '1rem' }}>
                                <Grid.Row verticalAlign="middle" textAlign="center" columns={2} style={{ margin: 0, padding: '0.25rem' }}>
                                    <Grid.Column>
                                        <PermissionsGateV hasPermission={probeState?.permissions?.editbasicdata}>
                                            <AnchorStyleButton onClick={() => setShowModal("updateNode")} >
                                                <Icon name="recycle" /> Upgrade
                                            </AnchorStyleButton>
                                        </PermissionsGateV>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <PermissionsGateV hasPermission={probeState?.permissions?.editbasicdata}>
                                            <AnchorStyleButton onClick={() => setShowModal("reboot")}>
                                                <Icon name="sync" /> Reboot
                                            </AnchorStyleButton>
                                        </PermissionsGateV>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row
                                    columns={(probeDetails.hasOwnProperty('devicemodel') && probeDetails.devicemodel.includes('rpi')) ? 2 : 1} textAlign='center'
                                    style={{ margin: 0, padding: '0.25rem' }}
                                >
                                    {probeDetails.hasOwnProperty('devicemodel') && probeDetails.devicemodel.includes('rpi') && (
                                        <Grid.Column>
                                            <PermissionsGateV hasPermission={probeState?.permissions?.editbasicdata}>
                                                <AnchorStyleButton title='Identify node' onClick={() => setShowModal('blink')}>
                                                    <Icon name="lightbulb" /> Blink
                                                </AnchorStyleButton>
                                            </PermissionsGateV>
                                        </Grid.Column>
                                    )}
                                    <Grid.Column>
                                        <PermissionsGateV hasPermission={probeState?.permissions?.editbasicdata}>
                                            <AnchorStyleButton title='Create default graphs' onClick={() => setShowModal('createGraphs')}>
                                                <Icon name="chart line" /> Graph
                                            </AnchorStyleButton>
                                        </PermissionsGateV>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row verticalAlign="middle" textAlign="center" columns={2} style={{ margin: 0, padding: '0.25rem' }}>
                                    <Grid.Column>
                                        <PermissionsGateV hasPermission={probeState?.permissions?.editbasicdata}>
                                            <AnchorStyleButton onClick={() => setShowModal("shutdown")}>
                                                <Icon name="shutdown" /> Shutdown
                                            </AnchorStyleButton>
                                        </PermissionsGateV>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <PermissionsGateV hasPermission={probeState?.permissions?.editbasicdata}>
                                            <AnchorStyleButton title='Move node to unmanaged state' onClick={() => setShowModal("deleteNode")}>
                                                <Icon name="trash alternate outline" /> Delete
                                            </AnchorStyleButton>
                                        </PermissionsGateV>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider hidden style={{ margin: '0 0 3rem 0' }}/>
            <VPN id={probeDetails.id}/>
            <NetworkManagment id={probeDetails.id} />
            <ProbeDetails probeId={probeDetails.id} />
            <ProbeData probeId={probeDetails.id} />
            <ProbePermissions probeId={probeDetails.id} />
            <InstalledModules probeId={probeDetails.id} />
            <AssignedModules probeId={probeDetails.id} />
            <UnassignedModules probeId={probeDetails.id} />

            <ConfirmationModal
                open={showModal === 'updateNode'}
                header="Upgrade node"
                content="Are you sure you want to schedule node for upgrade?"
                onConfirm={updateNode}
                onDismiss={() => setShowModal(undefined)}
            />
            <ConfirmationModal
                open={showModal === 'deleteNode'}
                header="Delete node"
                content={`Are you sure you want to delete node with ID ${probeid}?`}
                onConfirm={deleteNode}
                onDismiss={() => setShowModal(undefined)}
            />
            <ConfirmationModal
                open={showModal === 'reboot'}
                header="Reboot node"
                content="Are you sure you want to schedule node for reboot?"
                onConfirm={reboot}
                onDismiss={() => setShowModal(undefined)}
            />
            <ConfirmationModal
                open={showModal === 'shutdown'}
                header="Shutdown node"
                content="Are you sure you want to schedule node for shutdown?"
                onConfirm={shutdown}
                onDismiss={() => setShowModal(undefined)}
            />
            <ConfirmationModal
                open={showModal === 'blink'}
                header="Node identification"
                content="Are you sure you want to start node identification?"
                onConfirm={startBlinking}
                onDismiss={() => setShowModal(undefined)}
            />
            <ConfirmationModal
                open={showModal === 'assignLicense'}
                header="Apply license to node"
                content={
                    <>
                        Choose license:&emsp;
                        <Select
                            placeholder='Select license...'
                            options={availableLicenses}
                            selectOnBlur={false}
                            onChange={(_, { value }) => selectedLicenseRef.current = value}
                        />
                    </>}
                onConfirm={assignLicenseToProbe}
                onDismiss={() => setShowModal(undefined)}
            />
            <ConfirmationModal
                open={showModal === 'upgradeLicense'}
                header="Upgrade license"
                content={
                    <>
                        Choose license:&emsp;
                        <Select
                            placeholder='Select license...'
                            options={availableUpgradeLicense}
                            selectOnBlur={false}
                            onChange={(_, { value }) => selectedLicenseRef.current = value}
                        />
                    </>}
                onConfirm={upgradeLicense}
                onDismiss={() => setShowModal(undefined)}
            />
            <ConfirmationModal
                open={showModal === 'removeLicense'}
                header="Remove license from node"
                content={
                    <>
                        Are you sure you want to remove license from node?<br /><br />
                        <pre style={{ color: "red" }}>
                            Removing the license will remove all modules!<br />
                            Please save them as a template before removing license!
                        </pre>
                    </>}
                onConfirm={removeLicenseFromProbe}
                onDismiss={() => setShowModal(undefined)}
            />
            <ConfirmationModal
                open={showModal === 'createGraphs'}
                header="Create default graphs"
                content={
                    <>
                        Create default graphs for node {probeid}?<br/>
                        <pre style={{ color: "red" }}>
                            This action will regenerate all existing default dashboards
                        </pre>
                    </>}
                onConfirm={createGraphs}
                onDismiss={() => setShowModal(undefined)}
            />
            <Divider hidden style={{ marginTop: '2rem', marginBottom: '1rem' }} />
        </>
    );
};

export default ProbeEdit;