import { useMemo, useState } from 'react';
import { Form, Button, Dropdown, Header, Checkbox } from 'semantic-ui-react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import NMService from '../../../services/nm.service';
import ModuleBoxLayout from '../../../layouts/ModuleBox/ModuleBox';
import { useFetchAvailableModules } from '../../../hooks/useFetchAvailableModules';
import { useFetchProbeDetails } from '../../../hooks/useFetchProbeDetails';
import { useFetchProbeModuleBundle } from '../../../hooks/useFetchProbeModuleBundle';
import { useAtom } from 'jotai';
import { installedModulesAtom } from '../../../store/installedModules';
import { PermissionsGateV } from '../../../layouts/PermissionGate/PermissionGateV';
import { ErrorMessage } from '../../../components/ErrorMessage';

const installModuleFormSchema = yup.object().shape({
    module: yup.string().trim()
        .required("Module is required.")
})


const InstallModuleForm = (props) => {
    const formOptions = { resolver: yupResolver(installModuleFormSchema)}
    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm(formOptions);

    const probeId = useMemo(() => props.probeId, [props.probeId]);
    const [state, dispatch] = useAtom(installedModulesAtom);
    const [showFavourites, setShowFavourites] = useState(false);

    const {
        data: probeDetails
    } = useFetchProbeDetails({
        probeid: probeId
    });

    const {
        data: installedmodules,
        isLoading: installedModulesIsLoading,
        refetch: installedModulesRefetch
    } = useFetchProbeModuleBundle({
        probeid: probeId,
        select: data => data?.installedmodules ?? [],
        options: {
            refetchOnMount: 'always'
        }
    })

    const {
        data: availableModules,
        isLoading: availableModulesIsLoading
    } = useFetchAvailableModules({
        probeid: probeDetails.id,
        options: {
            refetchOnMount: 'always'
        }},
    );

    const getModules = () => 
        availableModules.map(({ id, version, description }) => {
                            if (installedmodules.findIndex((e) => e.module === id) === -1) {
                                return ({
                                    key: `${id}_${version}`,
                                    value: `${id}_${version}`,
                                    text: `${id} ${version}  -  ${description}`,
                                });
                            } else {
                                return null
                            }
                        }).filter(e => e)

    const getModuleFavourites = () => 
        availableModules.filter(e => e.favourite)
                        .map(({ id, version, description }) => {
                            if (installedmodules.findIndex((e) => e.module === id) === -1) {
                                return ({
                                    key: `${id}_${version}`,
                                    value: `${id}_${version}`,
                                    text: `${id} ${version}  -  ${description}`,
                                });
                            } else {
                                return null
                            }
                        }).filter(e => e)

    const onSubmit = async (values) => {
        const [module, version] = values.module.split('_');
        if (!probeId || !version || !module) return;

        await NMService.installAdditionalModule({ nodeid: probeId, version: version, module: module }).then(() => {
            installedModulesRefetch();
        }).catch(e => null)
    };

    if (availableModulesIsLoading || installedModulesIsLoading) {
        return null;
    }

    return (
        <ModuleBoxLayout>
            <Form className="basic segment" onSubmit={handleSubmit(onSubmit)}>
                <Header as="h4">
                    Install new module
                </Header>
                <Form.Field error={Boolean(errors.module)} width={8}>
                    <label>
                        Select a module
                        <Checkbox
                            checked={showFavourites}
                            style={{ float: 'right', fontSize: '0.875rem' }}
                            label='Only show favourites'
                            onClick={() => {setShowFavourites(p => !p); reset()}}
                        />
                    </label>
                    <Controller
                        name="module"
                        control={control}
                        defaultValue=""
                        render={({ field: { ref, ...field } }) => (
                            <Dropdown
                                {...field}
                                key={showFavourites}
                                selectOnBlur={false}
                                search
                                fluid
                                selection
                                placeholder="Select module"
                                onChange={(_, { value }) => field.onChange(value)}
                                options={showFavourites ? getModuleFavourites() : getModules()}
                            />
                        )}
                    />
                    {errors.module && <ErrorMessage>{errors.module.message}</ErrorMessage>}
                </Form.Field>
                <Form.Field align="left">
                    <PermissionsGateV hasPermission={state.hasPermission}>
                        <Button size="small" primary>
                            Install module
                        </Button>
                    </PermissionsGateV>
                    <Button
                        size="small"
                        type="button"
                        onClick={() => dispatch({
                            type: 'set-showType',
                            value: ''
                        })}
                    >
                        Cancel
                    </Button>
                </Form.Field>

            </Form>
        </ModuleBoxLayout>
    );
};

export default InstallModuleForm;
