import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { Form, Grid, Label, Input, Icon, Button, Dropdown, Header, Divider } from 'semantic-ui-react';
import NMService from '../../../services/nm.service';
import DashboardRow from './DashboardRow';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import { PermissionsGateV } from '../../../layouts/PermissionGate/PermissionGateV';
import { useSetAtom } from 'jotai';
import { addGlobalMessageAtom } from '../../../store/globalMessage';

function ModuleGraph(props) {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const { moduleid, instanceid } = useMemo(() => props.data, [props.data]);
    const probeId = useMemo(() => props.probeId, [props.probeId]);
    const hasPermission = props.hasPermission;
    const [vistemplates, setVizTemplates] = useState([]);
    const [dashboards, setDasboards] = useState([]);
    const [folders, setFolders] = useState([]);
    const [selectedDashId, setSelectedDashId] = useState('');
    const [showForm, setShowForm] = useState(undefined);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [variables, setVariables] = useState({});

    const {
        register,
        reset,
        control,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'variableValues',
    });

    const templateIdWatch = watch('templateid');

    const fetchSWModuleVisDashTemplates = useCallback(() => {
        NMService.getSWModuleVisDashTemplates(moduleid).then((response) => {
            setVizTemplates(response.data);
        }).catch(e => null)
    }, [moduleid]);

    const fetchNodeModuleGraph = useCallback(() => {
        NMService.getNodeModuleGraph(probeId, moduleid, instanceid).then((response) => 
            setDasboards(response.data)
        ).catch(e => null)
    }, [probeId, moduleid, instanceid]);

    const fetchFolder = useCallback(() => {
        NMService.getFolders(probeId).then((response) => {
            setFolders(response.data);
        }).catch(e => null)
    }, [probeId]);

    const getTemplateIdOptions = () => {
        return vistemplates.map((e) => ({
            key: e.id,
            value: e.id,
            text: e.id,
        }));
    };

    const getFolderOptions = () => {
        return folders.map((e) => ({
            key: e.uid || 'default',
            value: e.uid || 'default',
            text: e.title,
        }));
    };

    const getVariablesOptions = () => {
        return Object.keys(variables).map((e) => {
            const variable = e.substring(e.indexOf('{') + 1, e.lastIndexOf('}'));
            return {
                key: variable,
                value: variable,
                text: variable,
            };
        });
    };

    const required = (value) => {
        if (!value) {
            return 'Field is mandatory';
        }
        return null;
    };

    const validateVariable = () => {
        return null;
    };

    const deleteDashboard = async () => {
        await NMService.deleteNodeModuleGraph(selectedDashId).then((response) =>
            addGlobalMessage({
                header: 'Module dashboard was deleted!',
                content: `Module dashboard was successfully deleted.`,
                type: 'positive',
            }))
            .then(() => fetchNodeModuleGraph())
            .catch(e => null)
            .finally(() => {
                setShowDeleteModal(false);
                setSelectedDashId('');
            })
    };

    const onAddDashboardSubmit = async (values) => {
        const { variableValues, ...rest } = values;
        if (rest.description === undefined) {
            delete rest.description;
        }

        if (rest.folderuid === 'default') {
            delete rest.folderuid;
        }

        let variableValuesObj = {};
        try {
            variableValuesObj = variableValues.reduce((acc, current) => (acc[current.variable] = current.value), {});
        } catch {
            variableValuesObj = {};
        }

        let data = {};
        await NMService.getNodeDetails(probeId).then((response) => {
            data = {
                hwid: response.data.hwid,
                nodeid: response.data.id,
                instanceid,
                moduleid,
                ...rest,
                variableValues: variableValuesObj,
            };
        }).catch(e => null)

        await NMService.saveNodeModuleGraph(data).then((response) => {
            fetchNodeModuleGraph();
            setShowForm(undefined);
            reset();

            addGlobalMessage({
                header: 'Module dashboard was created!',
                content: `Module dashboard was successfully created.`,
                type: 'positive'
            });
        }).catch(e => null)
    };

    useEffect(() => {
        if (moduleid) {
            fetchSWModuleVisDashTemplates();
            fetchNodeModuleGraph();
        }
    }, [moduleid, fetchSWModuleVisDashTemplates, fetchNodeModuleGraph]);

    useEffect(() => {
        fetchFolder();
    }, [fetchFolder]);

    useEffect(() => {
        if (templateIdWatch) {
            const variables = vistemplates.find((e) => e.id === templateIdWatch).variables;
            setVariables(variables);
        }
    }, [vistemplates, templateIdWatch]);

    return (
        <React.Fragment>
            {dashboards.length > 0 && (
                <Grid divided="vertically" style={{ margin: '0 1.5rem' }}>
                    <Grid.Row divided style={{ padding: '0.5rem 0' }}>
                        <Grid.Column width={3} textAlign="left" style={{ margin: '0' }}>
                            <p style={{ fontWeight: 'bold' }}>Title</p>
                        </Grid.Column>
                        <Grid.Column width={4} textAlign="left" style={{ margin: '0' }}>
                            <p style={{ fontWeight: 'bold' }}>Description</p>
                        </Grid.Column>
                        <Grid.Column width={3} textAlign="left" style={{ margin: '0' }}>
                            <p style={{ fontWeight: 'bold' }}>Template</p>
                        </Grid.Column>
                        <Grid.Column width={4} textAlign="left" style={{ margin: '0' }}>
                            <p style={{ fontWeight: 'bold' }}>Url to Grafana</p>
                        </Grid.Column>
                        <Grid.Column width={2} textAlign="left" style={{ margin: '0' }}></Grid.Column>
                    </Grid.Row>

                    {dashboards.map((dash) => (
                        <DashboardRow
                            key={dash.uid}
                            moduleid={moduleid}
                            data={dash}
                            deleteDashboard={(uuid) => {
                                setSelectedDashId(uuid);
                                setShowDeleteModal(true);
                            }}
                        />
                    ))}

                    <Grid.Row style={{ padding: '0 0' }}>
                        <Grid.Column width={14} style={{ margin: '0.5rem 0' }}></Grid.Column>
                        <Grid.Column width={2} textAlign="center" style={{ margin: '0.5rem 0' }}>
                            <Button size="small" primary onClick={() => setShowForm('new_dashboard')}>
                                Add new
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}

            {(showForm === 'new_dashboard' || dashboards.length === 0) && (
                <Form style={{ padding: '0 0 0 1.5rem' }} onSubmit={handleSubmit(onAddDashboardSubmit)}>
                    <Grid>
                        <Grid.Row style={{ paddingTop: '1rem', paddingBottom: '0' }}>
                            <Header as="h4">Create new dashboard</Header>
                            <Divider />
                        </Grid.Row>
                        <Grid.Row verticalAlign="middle" style={{ padding: '0 0' }}>
                            <Grid.Column width={2}>
                                <label>Folder</label>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Form.Field error={Boolean(errors.folderuid)}>
                                    <Controller
                                        render={({ field, ref }) => (
                                            <Dropdown
                                                {...field}
                                                ref={ref}
                                                fluid
                                                selection
                                                placeholder="Select folder"
                                                onChange={(e, { value }) => field.onChange(value)}
                                                options={getFolderOptions()}
                                            />
                                        )}
                                        name="folderuid"
                                        control={control}
                                        rules={{ validate: required }}
                                        defaultValue=""
                                    />
                                </Form.Field>
                            </Grid.Column>
                            {errors.folderuid && errors.folderuid.type === 'validate' && (
                                <Grid.Column width={4}>
                                    <Label pointing="left" basic color="red">
                                        {errors.folderuid.message}
                                    </Label>
                                </Grid.Column>
                            )}
                        </Grid.Row>
                        <Grid.Row verticalAlign="middle" style={{ paddingBottom: '0' }}>
                            <Grid.Column width={2}>
                                <label>Title</label>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Form.Field error={Boolean(errors.title)}>
                                    <Controller
                                        render={({ field, ref }) => <Input {...field} ref={ref} placeholder="Title" />}
                                        name="title"
                                        control={control}
                                        rules={{ validate: required }}
                                        defaultValue=""
                                    />
                                </Form.Field>
                            </Grid.Column>
                            {errors.title && errors.title.type === 'validate' && (
                                <Grid.Column width={4}>
                                    <Label pointing="left" basic color="red">
                                        {errors.title.message}
                                    </Label>
                                </Grid.Column>
                            )}
                        </Grid.Row>
                        <Grid.Row verticalAlign="middle" style={{ paddingBottom: '0' }}>
                            <Grid.Column width={2}>
                                <label>Description</label>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Form.Field>
                                    <Controller
                                        render={({ field, ref }) => <Input {...field} ref={ref} placeholder="Description" />}
                                        name="description"
                                        control={control}
                                        defaultValue=""
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row verticalAlign="middle" style={{ paddingBottom: '0' }}>
                            <Grid.Column width={2}>
                                <label>Template ID</label>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Form.Field error={Boolean(errors.templateid)}>
                                    <Controller
                                        render={({ field, ref }) => (
                                            <Dropdown
                                                {...field}
                                                ref={ref}
                                                fluid
                                                selection
                                                placeholder="Select template"
                                                onChange={(e, { value }) => field.onChange(value)}
                                                options={getTemplateIdOptions()}
                                            />
                                        )}
                                        name="templateid"
                                        control={control}
                                        defaultValue=""
                                    />
                                </Form.Field>
                            </Grid.Column>
                            {errors.templateid && errors.templateid.type === 'validate' && (
                                <Grid.Column width={4}>
                                    <Label pointing="left" basic color="red">
                                        {errors.templateid.message}
                                    </Label>
                                </Grid.Column>
                            )}
                        </Grid.Row>
                        <Grid.Row verticalAlign="middle" style={{ paddingBottom: "0" }}>
                            {templateIdWatch && getVariablesOptions().length !== 0 && (
                                <Grid.Column width={3} textAlign="left">
                                    <label>Variable values</label>
                                    <Icon
                                        link
                                        name="plus"
                                        style={{ color: "#4183C4", marginLeft: "1rem" }}
                                        onClick={() => append({ variable: "", value: "" })}
                                    ></Icon>
                                </Grid.Column>
                            )}
                            <Grid.Column width={4}></Grid.Column>
                        </Grid.Row>
                        {fields.map((field, index) => (
                            <Grid.Row key={field.id} verticalAlign="middle" style={{ paddingBottom: '0' }}>
                                <Grid.Column width={2} textAlign="right">
                                    <Form.Field>
                                        <Icon
                                            link
                                            name="trash alternate"
                                            style={{ color: '#4183C4', marginLeft: '1rem' }}
                                            onClick={() => remove(index)}
                                        ></Icon>
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Form.Field error={Boolean(errors.variableValues?.[index]?.variable)}>
                                        <Dropdown
                                            {...register(`variableValues.${index}.variable`, {
                                                validate: (value) => validateVariable(value),
                                            })}
                                            fluid
                                            selection
                                            required
                                            placeholder="Select variable"
                                            onChange={(e, { name, value }) => {
                                                setValue(name, value);
                                            }}
                                            defaultValue={getValues(`variableValues.${index}.variable`)}
                                            options={getVariablesOptions()}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Form.Field>
                                        <Input
                                            {...register(`variableValues.${index}.value`)}
                                            required
                                            disabled={Boolean(props.selectedTemplateId)}
                                            placeholder="Value"
                                            onChange={(e, { name, value }) => {
                                                setValue(name, value);
                                            }}
                                            defaultValue={getValues(`variableValues.${index}.value`)}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        ))}
                        <Grid.Row>
                            <Grid.Column width={6}>
                                <Form.Field align="left">
                                    <PermissionsGateV hasPermission={hasPermission}>
                                        <Button size="small" type="submit" primary>
                                            Create
                                        </Button>
                                    </PermissionsGateV>
                                    <PermissionsGateV hasPermission={hasPermission}>
                                        {dashboards.length > 0 ? (
                                            <Button
                                                size="small"
                                                type="button"
                                                onClick={() => {
                                                    reset();
                                                    setShowForm(undefined);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        ) : (
                                            <Button
                                                size="small"
                                                type="button"
                                                onClick={() => {
                                                    reset();
                                                }}
                                            >
                                                Reset
                                            </Button>
                                        )}
                                    </PermissionsGateV>
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            )}

            <ConfirmationModal
                open={showDeleteModal}
                header="Delete dashboard"
                content="Are you sure you want to delete dashboard ?"
                onConfirm={deleteDashboard}
                onDismiss={() => {
                    setShowDeleteModal(false);
                    setSelectedDashId('');
                }}
            />
        </React.Fragment>
    );
}

export default ModuleGraph;