import { Form, Table } from "semantic-ui-react";
import NMService from "../../services/nm.service";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSetAtom } from "jotai";
import { addGlobalMessageAtom } from "../../store/globalMessage";
import CustomHeader from "../../components/CustomHeader";
import { useFormFields } from "../../hooks/useFormFields";

const PermissionsConfig = () => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [show, setShow] = useState(false);
    const [initial, setInitial] = useState({});
    const rows = [
        { header: "Edit basic data", accessor: "editbasicdata" },
        { header: "Assign modules", accessor: "assignmodules" },
        { header: "Change permissions", accessor: "editpermissions" },
    ];

    const { handleSubmit, register, setValue, reset, watch, formState: { errors } } = useForm();
    const { renderCheckbox } = useFormFields({ register, errors, setValue, watch });

    const onSubmit = (values) => {
        let {editbasicdata, assignmodules, editpermissions, managedby} = values
        const nodePermissionsData = {
            name: "node.defaultPermissions",
            objValue: JSON.stringify({ editbasicdata, assignmodules, editpermissions }),
        };

        const nodeModulePermissionsData = {
            name: "nodemodule.defaultPermissions",
            objValue: JSON.stringify({ managedby }),
        };

        const updateNode = NMService.updateGlobalConfigValue(nodePermissionsData).catch(e => null);
        const updateNodeModule = NMService.updateGlobalConfigValue(nodeModulePermissionsData).catch(e => null);
        Promise.all([updateNode, updateNodeModule])
            .then(r => addGlobalMessage({
                            header: 'Successfully updated global permissions',
                            content: "Successfully updated global permissions",
                            type: 'positive',
                        }))
            .then(r => setInitial(values))
    }

    useEffect(() => {
        NMService.getGlobalConfigValue("node.defaultPermissions")
            .then((r) => {
                setInitial(p => ({ ...p, ...r.data }))
                Object.entries(r.data).forEach((e) => reset((p) => ({ ...p, [e[0]]: e[1] })))
            })
            .catch((e) => null);
        NMService.getGlobalConfigValue("nodemodule.defaultPermissions")
            .then((r) => {
                setInitial(p => ({ ...p, managedby: r.data.managedby }))
                reset((p) => ({ ...p, managedby: r.data.managedby }))
            })
            .catch((e) => null);
    }, [reset]);

    return (
        <>
            <CustomHeader
                title='Permissions'
                toggle={() => setShow((p) => !p)}
                show={show}
            />
            <Form
                className="basic segment"
                hidden={!show}
                style={{ padding: "0" }}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Table celled size="small" compact attached="top" striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan="5" textAlign="center">
                                Default node permissions
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {rows.map((e, i) => (
                            <Table.Row key={i}>
                                <Table.Cell style={{ fontWeight: "bold" }} width={4} textAlign="center">{e.header}</Table.Cell>
                                <Table.Cell>{renderCheckbox("owner", e.accessor, { radio: true, checked: 'owner' })}</Table.Cell>
                                <Table.Cell>{renderCheckbox("group admin", e.accessor, { radio: true, checked: 'groupadmin' })}</Table.Cell>
                                <Table.Cell>{renderCheckbox("members", e.accessor, { radio: true, checked: 'members' })}</Table.Cell>
                                <Table.Cell>{renderCheckbox("anyone", e.accessor, { radio: true, checked: 'any' })}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                <Table celled size="small" compact attached="bottom">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan="5" textAlign="center">
                                Default node module permissions
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell style={{ fontWeight: "bold" }} width={4} textAlign="center">Managed by</Table.Cell>
                            <Table.Cell>{renderCheckbox("owner", 'managedby', { radio: true, checked: 'owner' })}</Table.Cell>
                            <Table.Cell>{renderCheckbox("group admin", 'managedby', { radio: true, checked: 'groupadmin' })}</Table.Cell>
                            <Table.Cell>{renderCheckbox("members", 'managedby', { radio: true, checked: 'members' })}</Table.Cell>
                            <Table.Cell>{renderCheckbox("anyone", 'managedby', { radio: true, checked: 'any' })}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <Form.Group align="left">
                    <Form.Button content="Update" size="small" type="submit" primary/>
                    <Form.Button content="Reset" size="small" type="button" onClick={() => reset(initial)}/>
                </Form.Group>
            </Form>
        </>
    );
};

export default PermissionsConfig;
