import { Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAtom, useSetAtom } from 'jotai';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Header, Form } from 'semantic-ui-react';
import NMService from '../../services/nm.service';
import { addGlobalMessageAtom } from '../../store/globalMessage';
import { locationsActions, locationsAtom } from '../../store/locations';
import { useFetchLocations } from '../../hooks/useFetchLocations';
import { useFormFields } from '../../hooks/useFormFields';
import { useFetchLocationParameters } from '../../hooks/useFetchLocationParameters';
import LocationParametersForm from './LocationParametersForm';

const locationParamsFormSchema = yup.object().shape({
    name: yup.string().trim()
        .required("Name is required.")
        .min(2, "Name length should be at least 2 characters.")
        .max(128, "Name cannot exceed more than 128 characters.")
        .matches(/^[a-zA-Z0-9%_.,; /+\u00C0-\u024F\u1E00-\u1EFF-]{2,}$/, 'Name can contain letters, numbers, %_.,;'),
    description: yup.string().trim()
        .required("Description is required.")
        .min(2, "Description length should be at least 2 characters.")
        .max(255, "Description cannot exceed more than 255 characters.")
        .matches(/^[a-zA-Z0-9%_.,; /+\u00C0-\u024F\u1E00-\u1EFF-]{2,}$/, 'Description can contain letters, numbers, %_.;'),
    latitude: yup.number()
        .min(-90, "Latitude must be greater than or equal to -90")
        .max(90, "Latitude must be less than or equal to 90"),
    longitude: yup.number()
        .min(-180, "Latitude must be  greater than or equal to -180")
        .max(180, "Latitude must be less than or equal to 180")
})


const LocationsForm = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [state, dispatch] = useAtom(locationsAtom);

    const formOptions = {
        resolver: yupResolver(locationParamsFormSchema),
        defaultValues: {
            name: "",
            description: "",
            longitude: 0,
            latitude: 0
        }
    };

    const {
        data: locationParams = [],
    } = useFetchLocationParameters({
        options: {
            refetchOnMount: 'always'
        },
        select: e => e.map(e => ({key: e.name, text: `${e.name} (${e.description})`, value: e.name}))
    });

    const {
        handleSubmit,
        reset,
        register,
        setValue,
        getValues,
        watch,
        formState: { errors },
    } = useForm(formOptions);

    const { renderInput } = useFormFields({ register, errors, setValue, watch });

    const {
        refetch: refetchlocationList
    } = useFetchLocations({})

    const updateLocationParams = (data) => {onSubmit(({...getValues(), data: data}))}

    const onSubmit =  (data) => {
        let message = undefined
        let payload = {
            ...data,
            name: data.name.trim(),
            description: data.description.trim(),
        }
        if (state.selectedLocation?.id) {
            message = 'Location was successfully updated'
            payload = {...payload, id: state.selectedLocation.id }
        }
        if (!("data" in payload)) {
            payload  = {...payload, data: state.selectedLocation.data }
        }
        NMService.updateLocation((payload)).then(() =>
            addGlobalMessage({
                header: message ? message : 'New location successfully added',
                content: message ? message : 'New location successfully added',
                type: 'positive',
            }))
            .then(() => {
                dispatch({ type: locationsActions.CLEAR_TYPE })
                refetchlocationList();
            }).catch(e => null)
    };

    useEffect(() => {
        if (Object.keys(state.selectedLocation).length > 0) {
            const { name, description, latitude, longitude } = state.selectedLocation;
            reset({ name, description, latitude, longitude });
        } else {
            reset({ name: "", description: "", longitude: 0, latitude: 0 })
        }
    }, [state.selectedLocation, reset]);

    return (
        <Fragment>
            <Header as="h4" dividing>
                Basic location data
            </Header>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                    {renderInput("Location name", "name", { width: 4 })}
                    {renderInput("Description", "description", { width: 8 })}
                </Form.Group>
                <Form.Group widths={4}>
                    {renderInput("Latitude", "latitude", { inputType: "number", decimal: true })}
                    {renderInput("Longitude", "longitude", { inputType: "number", decimal: true })}
                </Form.Group>
                {!!!state.selectedLocation?.id &&
                    <Form.Group>
                        <Form.Button
                            size="small"
                            type='submit'
                            primary
                            content="Add"
                        />
                        <Form.Button
                            size="small"
                            type="button"
                            content="Cancel"
                            onClick={() => dispatch({ type: locationsActions.CLEAR_TYPE })}
                        />
                    </Form.Group>
                }
            </Form>
            {state.selectedLocation?.id && 
                <LocationParametersForm
                    parameters={locationParams}
                    id={state.selectedLocation.id}
                    data={state.selectedLocation?.data.rawjsondata}
                    post={updateLocationParams}
                    close={() => dispatch({ type: locationsActions.CLEAR_TYPE })}
                />
            }
        </Fragment>
    );
}

export default LocationsForm;