import { Outlet } from "react-router-dom";
import { Container } from "semantic-ui-react";
import GlobalMessages from "./GlobalMessages/GlobalMessages";
import MainHeader from "./MainHeader";
import NMService from "../services/nm.service";
import { useEffect, useState } from "react";
import EulaForm from "../pages/licenseManagment/EulaForm";
import { TENANT_ADMIN } from "../constants/layout";

export const HomeLayout = () => {
    const [eulaAccepted, setEulaAccepted] = useState(undefined);

    const fetchEulaStatus = () =>
        NMService.getEulaAgreementStatus()
            .then((r) => setEulaAccepted(r.data))
            .catch((e) => null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user?.userdata?.role !== TENANT_ADMIN) {
            fetchEulaStatus();
        } else {
            setEulaAccepted({ accepted: true });
        }
    }, []);

    return (
        <Container style={{ width: "1450px", padding: "0 1rem" }}>
        {/* //     {eulaAccepted?.accepted === undefined ? null : eulaAccepted?.accepted === false ? (
        //         <EulaForm refetch={fetchEulaStatus} />
        //     ) : ( */}
                <>
                    <MainHeader />
                    <GlobalMessages />
                    <Outlet />
                </>
        {/* //     )} */}
        </Container>
    );
};
